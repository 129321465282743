.App{
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #06032c;
}

@media only screen and (max-width: 768px) {
    .App{
        width: 100%;
    }
  }