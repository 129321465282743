.description-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding: 25px;
    text-align: justify;
    padding: 80px;
}

.description-title{
    font-size: 75px;
    color: #27a68e;
    border-right: #27a68e 1px solid;
    padding: 100px;
}

.description-paragraph{
    font-size: 25px;
    color: #eadaff;
}

.description-right{
    width: 750px;
}

@media only screen and (max-width: 768px) {
    .description-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0;
        padding: 20px;
        text-align: center;
        padding-top: 0;
    }

    .description-title{
        border: none;
        font-size: 50px;
        width: auto;
        padding: 0;
    }

    .description-right{
        width: 100%;
    }

    .description-left{
        width: 100%;
    }

    .description-paragraph{
        font-size: 18px;
    }
  }