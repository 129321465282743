.prevent-box{
    max-width: 350px;
    min-width: 280px;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 200px;
    background: linear-gradient(0deg, #d4b8f9 0%, #eadaff 100%);
    padding-left: 20px;
    padding-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.guest-name{
    font-size: 20pt;
    color: black;
    font-weight: 600;
    margin-left: 0;
    z-index: 10;
}
.guest-title{
    font-size: 12pt;
    color: black;
    font-weight: 400;
}

.guest-corp{
    font-size: 12pt;
    color: black;
    font-weight: 300;
}

.date{
  position: absolute;
  bottom: 20px;
}

.guest-photo{
    position: absolute;
    height: 200px;
    bottom: -25px;
    right: -55px;
    z-index: 1;
    
}


.linkedin-logo{
    font-size: 20pt;
}

  
@media only screen and (max-width: 768px) {
    .prevent-title{font-size: 40px;}
    .previous-events{padding: 0px;}
    .guest-name {
        color: black !important;
        margin-left: 0 !important; 
    }
   }