/* Button.css */
.ButtonContainer {
  display: inline-block;
  padding: 13px 23px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #06032c;
  background-color: #eadaff;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  letter-spacing: 1px;
}

.ButtonContainer:hover {
  background-color: #06032c;
  color: #eadaff;
}

.ButtonContainer {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@media only screen and (max-width: 768px){
  .ButtonContainer{
    display: flex;
    flex-direction: column;
    max-width: max-content;
    margin: auto;
    justify-content: center;
  }
}