.previous-events{
    padding: 25px;
    padding-top: 125px;
}

.prevent-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 25px;
}

.prevent-title{
    font-size: 50px;
    font-weight: 600;
    color: #eadaff;
    text-align: center;
    border-bottom: #eadaff 1px solid;
    width: max-content;
    margin: auto;
}


  .embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
  }
  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
  }

  
@media only screen and (max-width: 768px) {
    .prevent-title{font-size: 40px;}
    .previous-events{padding: 0px;}
   }

.academic-year-section {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 50px;
}

.academic-year-title {
  font-size: 30px;
  color: #eadaff;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .academic-year-title {
    font-size: 25px;
  }
}