.countdown .content {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: auto;
	gap: 12px;
}
.countdown h2 {
	margin-bottom: 20px;
	font-size: 50px;
}

.countdown .box {
	width: calc(100% / 4);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 150px;
	max-width: 150px;
}

.countdown .value {
	font-size: 30px;
	font-weight: 600;
	background-color: #27a68e;
	color: #eadaff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	width: 80px;
	height: 80px;
	position: relative;
}


@media screen and (min-width: 790px) {
	.countdown .value {
		font-size: 50px;
		height: 100px;
		width: 100px;
	}
	.countdown .box {
		max-width: 100px;
		height: 150px;
	}
}

@media only screen and (max-width: 768px) {

	.countdown .content {
		gap: 25px;
	}

    .countdown .value {
		font-size: 30px;
		height: 60px;
		width: 60px;
	}
	.countdown .box {
		max-width: 60px;
		height: 90px;
	}

	.countdown .label {
		font-size: 15px !important;
		letter-spacing: 1px !important;
	}

  }

.countdown .value::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 1px;
	z-index: 2;
}
.countdown .value::after {
	content: "";
	position: absolute;
	z-index: 2;
	left: -7px;
	width: 10px;
	height: 20px;
	background-color: #969696;
}

.countdown .label {
	color: #eadaff;
	font-size: 20px;
	font-weight: 100;
	letter-spacing: 4px;
	margin-top: 10px;
}