.page-cover {
  display: flex;
  flex-direction: column;
  background-image: url("../../../public/Images/bck3.webp");
  min-height: 100vh;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(51, 22, 63, 0.482);
  align-items: center;
}

.main-cover {
  display: flex;
  flex-direction: row-reverse;
  gap: 75px;
  align-items: center;
}

.bizbize-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
}

.main-title {
  color: white;
  font-size: 50px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.skylab-logo{
    height: 75px;
    margin-top: 100px;
}


@media only screen and (max-width: 768px) {
  .page-cover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 17vh;
    min-height: calc(100vh - 20px);
  }

  .main-cover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10vhpx;
  }

  .bizbize-logo{
    width: 180px;
    height: 180px;
  }

  .main-title{
    font-size: 30px;
    text-align: center;
  }

  
  .skylab-logo{
    height: 70px;
    margin-top: 0;
  }

  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}